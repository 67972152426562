<template>
	<a
		:id="id ? 'cta--' + id : null"
		:href="urlWithSource"
		@click.prevent="handleClick"
	>
		<slot>
			{{ buttonText }}
			<svg v-if="arrow" class="cta__arrow ml-1" width="16" height="16" viewBox="0 0 58 49" fill="none">
				<path d="M33.3015 0.564453L57.5025 24.7655L33.3015 48.9665H17.3288L35.7216 30.5737H0.226807V18.9572H35.7216L17.3288 0.564453H33.3015Z" fill="currentColor"></path>
			</svg>
		</slot>
	</a>
</template>

<script>
	export default {
		name: 'CtaBanner',
		props: {
			buttonText: {
				type: String,
				default: 'Sign Up Free'
			},
			url: {
				type: String,
				default: '/signup'
			},
			id: {
				type: String,
				default: undefined
			},
			type: {
				type: String,
				default: undefined
			},
			position: {
				type: String,
				validator: value => {
					return value === 'top' || value === 'middle' || value === 'bottom' || value === 'sticky';
				},
				default: undefined
			},
			signupSource: undefined,
			arrow: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				automaticSignupSource: undefined,
				urlWithSource: this.url
			};
		},
		mounted() {
			// Add a signup source if necessary
			if (this.url.indexOf('?source=') === -1) {
				if (this.signupSource)
					this.urlWithSource = this.urlWithSource + '?source=' + this.signupSource;
				else if (this.url.includes('/signup')) {
					if (window.location.pathname === '/')
						this.automaticSignupSource = 'home';
					else {
						const pathParts = window.location.pathname.split('/');
						this.automaticSignupSource = pathParts[2] ? pathParts[1] + '-' + pathParts[2] : pathParts[1];
					}
					this.urlWithSource = this.urlWithSource + '?source=' + this.automaticSignupSource;
				}
			}
		},
		methods: {
			handleClick() {
				const gaData = {
					page_path: window.location.pathname,
					CTA_ID: this.id,
					CTA_type: this.type,
					CTA_position: this.position,
					CTA_targetURL: this.urlWithSource
				};
				this.$gtag.event('cta_click', gaData);
				this.$router.push(this.urlWithSource);
			}
		}
	};
</script>